// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y5yu9fiSw78JWPX2wa2z{width:100%;padding:20px;background-color:#fff;border-radius:4px;box-shadow:0 4px 8px 0 rgba(0,0,0,.2);transition:.3s}.Y5yu9fiSw78JWPX2wa2z:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,.2)}", "",{"version":3,"sources":["webpack://./src/common/components/DemoCard/index.module.scss","webpack://./src/common/styles/theme.scss","webpack://./src/common/styles/card.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,YAAA,CACA,qBCFM,CDGN,iBAAA,CENA,qCAAA,CACA,cAAA,CAEA,4BACE,sCAAA","sourcesContent":["@import 'src/common/styles/theme.scss';\n@import 'src/common/styles/card.scss';\n\n.main {\n  width: 100%;\n  padding: 20px;\n  background-color: $white;\n  border-radius: 4px;\n\n  @include card-style;\n}\n","// Colors\n$paua: #2b1d52;\n$soft-iris: #e6e4ea;\n$black: #000000;\n$white: #ffffff;\n","@mixin card-style {\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);\n  transition: 0.3s;\n\n  &:hover {\n    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Y5yu9fiSw78JWPX2wa2z"
};
export default ___CSS_LOADER_EXPORT___;
