// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._KCuZidN3DCAXPeiszZU{width:100%;padding:20px;position:sticky;top:10px}", "",{"version":3,"sources":["webpack://./src/views/WalletNotesView/index.module.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,YAAA,CAEA,eAAA,CACA,QAAA","sourcesContent":["@import 'src/common/styles/theme.scss';\n@import 'src/common/styles/card.scss';\n\n.main {\n  width: 100%;\n  padding: 20px;\n\n  position: sticky;\n  top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "_KCuZidN3DCAXPeiszZU"
};
export default ___CSS_LOADER_EXPORT___;
