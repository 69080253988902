// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QzLK4YvnflX8jTCSuZud{display:grid;width:100%;height:70px;grid-template-columns:1fr 3fr 1fr;grid-template-rows:100%;background-color:#2b1d52;color:#e6e4ea;box-shadow:0 4px 8px 0 rgba(0,0,0,.2);transition:.3s}.QzLK4YvnflX8jTCSuZud:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,.2)}.QzLK4YvnflX8jTCSuZud>div{display:flex;justify-content:center;align-items:center}.QzLK4YvnflX8jTCSuZud>div:nth-child(1) img{height:85%}.QzLK4YvnflX8jTCSuZud>div:nth-child(2){font-size:20px}", "",{"version":3,"sources":["webpack://./src/common/components/Header/index.module.scss","webpack://./src/common/styles/theme.scss","webpack://./src/common/styles/card.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,UAAA,CACA,WAAA,CACA,iCAAA,CACA,uBAAA,CAEA,wBCTK,CDUL,aCTU,CCDV,qCAAA,CACA,cAAA,CAEA,4BACE,sCAAA,CFUF,0BACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAIA,2CACE,UAAA,CAIJ,uCACE,cAAA","sourcesContent":["@import 'src/common/styles/theme.scss';\n@import 'src/common/styles/card.scss';\n\n.main {\n  display: grid;\n  width: 100%;\n  height: 70px;\n  grid-template-columns: 1fr 3fr 1fr;\n  grid-template-rows: 100%;\n\n  background-color: $paua;\n  color: $soft-iris;\n\n  @include card-style;\n\n  > div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  > div:nth-child(1) {\n    img {\n      height: 85%;\n    }\n  }\n\n  > div:nth-child(2) {\n    font-size: 20px;\n  }\n}\n","// Colors\n$paua: #2b1d52;\n$soft-iris: #e6e4ea;\n$black: #000000;\n$white: #ffffff;\n","@mixin card-style {\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);\n  transition: 0.3s;\n\n  &:hover {\n    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "QzLK4YvnflX8jTCSuZud"
};
export default ___CSS_LOADER_EXPORT___;
